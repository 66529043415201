$default-margin: 12px
$primary-color: #17293e
$secondary-color: #077cb3
$tertiary-color: #eb443c

@mixin flex
  display: flex
  align-items: center

@mixin bold
  font-weight: bold

@mixin ellipsis
  white-space: nowrap
  text-overflow: ellipsis
  overflow: hidden

@mixin verticalSeparator($height)
  width: 1px
  margin-right: 8px
  height: $height
  background-image: linear-gradient(#a6a4b7 50%, hsla(0, 0%, 100%, 0) 0)
  background-position: 100%
  background-size: 1px 2px
  background-repeat: repeat-y
  display: inline-block
  vertical-align: top

@mixin horizontalSeparator($width: 100%, $color: #a0bed8)
  width: $width
  height: 1px
  background-image: linear-gradient(80deg, $color, rgba(65, 82, 113, 0.4) 44%, transparent 44%, transparent 100%)
  background-size: 3px 1px
  background-repeat: repeat-x
  background-position: bottom left

@mixin customButton($width,$height)
  width: $width
  height: $height
  border: 1px solid #d0d4d7
  border-radius: 2px
  background: linear-gradient(to bottom, #f9f9fb 0%, #f7f7f7 50%, #f4f4f4 100%)
  box-shadow: inset 0 1px #fff
  padding: 0 8px
  @include flex
  justify-content: center
  > span
    color: inherit
    font-size: inherit !important

@mixin viewModeTabs
  @include flex
  border: 1px solid #c3cdd4
  border-radius: 2px
  margin-right: 10px

  > div
    @include flex
    justify-content: center
    min-width: 86px
    height: 20px
    padding: 0 8px
    line-height: 12px
    background: #ffffff linear-gradient(to bottom, #ffffff 0%, #f4f4f4 100%)
    color: rgba($primary-color, .8)
    @include bold
    text-transform: capitalize

    &:not(.selected-tab):hover
      cursor: pointer
      color: $primary-color

    &.selected-tab
      color: #fff
      background: #c73832 linear-gradient(to bottom, #c73832 0%, #e04239 11%, #eb433a 16%, #ef4339 21%, #ed4238 26%, #e8453c 37%, #eb443c 42%, #eb443c 100%)

    &:not(:first-of-type)
      border-left: 1px solid #c3cdd4


