@import '../../../base'
.bets-reports
  display: flex
  flex-direction: column
  min-width: 1280px

.bets-m2d
  max-width: 960px
  position: relative
  margin-bottom: 10px

  &.ant-collapse > .ant-collapse-item .ant-collapse-header
    padding: 4px 12px
    min-height: 32px
    @include flex

    .ant-spin-nested-loading
      margin-right: auto

    .ant-spin-container
      min-height: auto

    .ant-collapse-header-text
      @include flex
      flex: 1
    form.ant-form
      margin-left: auto

    .ant-form
      @include flex
      font-weight: normal

      .ant-form-item
        margin: 0

        .ant-select
          min-width: 80px

  &.ant-collapse .ant-collapse-content > .ant-collapse-content-box
    padding: 0

  .bets-m2d-content
    line-height: 12px
    padding: 0 14px

    > div:first-of-type
      margin-left: 76px
      height: 38px
      @include flex

      label
        @include bold
        text-transform: uppercase
        flex: 1 100%
        padding-left: 8px

    .bets-m2d-total-stake
      $color: #8d6a9f

      label, .bets-m2d-currency-col strong, .bets-m2d-currency-col > span
        color: $color

    .bets-m2d-settled
      $color: #376dc4

      label, .bets-m2d-currency-col strong, .bets-m2d-currency-col > span
        color: $color

    .bets-m2d-payout
      $color: #ec2d56

      label, .bets-m2d-currency-col strong, .bets-m2d-currency-col > span
        color: $color

    .bets-m2d-ggr
      $color: #06bd2f

      label, .bets-m2d-currency-col strong, .bets-m2d-currency-col > span
        color: $color

    .bets-m2d-total-stake, .bets-m2d-settled, .bets-m2d-payout, .bets-m2d-ggr
      @include flex
      margin-bottom: 10px

      label
        @include bold
        width: 76px
        min-width: 76px
        padding: 0 12px 0 0
        text-transform: uppercase
        text-align: right
        line-height: 14px

      .bets-m2d-currency-row
        @include flex
        border: 2px solid #e3e9ec
        min-height: 50px
        padding: 8px 0

        border-radius: 3px
        flex: 1

      .bets-m2d-currency-col
        flex: 1
        padding: 0 8px
        width: auto

        &:not(:last-of-type)
          @include verticalSeparator("100%")
          margin-right: 0

        > .currency-container:first-of-type
          @include flex
          @include bold

          span
            font-size: 15px !important

        > .currency-container:nth-child(2)
          margin-top: 4px
          opacity: .8
          color: #17293e

        > div
          margin-top: 2px

          > span
            color: #8596ad

  .bet-m2d-footer
    @include flex
    justify-content: flex-end
    padding: 0 20px
    background: #fafbfd
    height: 39px
    border-top: 1px solid #e4e8eb
    line-height: 12px

    svg
      width: 14px
      margin-left: 10px

    strong
      margin-left: 4px
      font-size: 14px
.bets-m2d-header-collapsed
  @include flex

  .ant-form
    margin-left: 20px

  .bets-m2d-details
    font-weight: normal
    background: #ecf5fc
    border: 1px solid #d1dbe2
    @include flex
    height: 22px
    margin-left: 10px
    padding: 0 2px
    border-radius: 3px
    font-size: 11px
    color: #17293e

    > span
      padding: 0 8px
      height: 100%
      @include flex

      &:first-of-type
        border-right: 1px solid #d1dbe2

    strong
      margin-right: 4px

.bets-list
  max-width: 1280px
  height: auto !important
  flex: 1 1 100%
  overflow: hidden

.ant-form.bets-filter
  @include flex
  box-shadow: 0 1px #e4e8eb
  height: 38px
  padding-left: 10px
  background: #fafbfd

  .ant-form-item
    margin-right: 10px

    &.flex
      margin-left: auto

  .ant-select-single
    min-width: 82px

  .ant-input-search
    width: 220px

  .ant-picker-range
    margin-right: 2px

  .ant-input-number
    width: 120px
    margin-left: 10px

  .vertical-separator
    margin-left: 0
    + .ant-form-item .ant-form-item-control-input-content
      @include flex

.bets-table
  .ant-table-cell *
    line-height: 14px

  .bets-created-date-column, .bets-settled-date-column
    span
      display: block

  .bets-type-column span
    display: block

  td.bets-table-events
    cursor: pointer

    p
      margin: 0
      @include flex

    > p
      flex-wrap: wrap

      .currency-container
        margin-left: 4px

      &:first-of-type
        margin-bottom: 4px

      span:not(.bets-table-events-more):not(.currency-container):not(.currency-container span)
        opacity: .8

      .bets-table-events-more
        margin-left: auto

  td.bets-device-ip-column
    text-align: center

    span
      display: block
      margin-top: 2px
      font-size: 11px

    svg
      height: 15px

  td.bets-result-column
    > div
      color: #e95456 !important

    > span
      display: block
      color: rgba(#637a95, .8)
      font-size: 11px !important

  td.bets-stake-win-column > span
    &:nth-child(2)
      color: rgba(#637a95, .8)
      display: block
      font-size: 11px !important
      margin-bottom: 2px

    &:nth-child(3)
      display: block
      color: #e95456

  td.bets-status-column
    text-align: center
    width: 48px

    .bet-status-td-manual
      @include flex
      flex-direction: column

      > i
        font-style: normal
        width: 11px
        height: 11px
        box-shadow: inset 0 0 0 1px $tertiary-color
        color: $tertiary-color !important
        line-height: 11px !important
        font-size: 10px !important
        @include bold
        position: absolute
        right: 2px
        top: 2px

      > span
        font-size: 11px !important
        @include ellipsis
        position: absolute
        max-width: calc(100% - 10px)
        bottom: 4px

  .ant-table-expanded-row
    .ant-card
      border-bottom: 0

    .ant-table
      border-top: 1px solid #e4e8eb
      border-radius: 0

    th
      height: 28px !important

      &:not(:last-of-type)
        border-right: 1px solid #e4e8eb
        border-left: 0

      &:before
        content: none !important

    td
      background: #fdfdfe !important
      padding: 10px !important
      border-bottom: 1px solid #e4e8eb
      border-right: 1px solid #e4e8eb

      &:last-of-type
        border-right: 0

      .bet-status-td-manual > span
        bottom: 10px
      &.ant-table-cell-rejected-info
        padding-right: 0!important
        padding-left: 0!important
        padding-bottom: 0!important

        .ant-table-bordered
          height: auto!important
          margin-bottom: -1px
        .anticon-info-circle
          margin-left: 10px
          vertical-align: top

    .bet-details-header
      height: 28px
      @include flex
      padding: 0 10px
      background: #fafbfd

    .bets-details-n-column
      text-align: center
      width: 50px

    .bets-details-odds-column
      width: 100px

    td.bets-details-event-column
      width: 368px

      > span
        display: block

    td.bets-details-bet-column > span
      display: block

    .bets-details-results-column
      width: 112px

    td.bets-details-results-column .ant-select
      width: 100%

.bets-control-edit-form
  margin-left: auto
  @include flex

  .ant-form-item
    margin: 0

    .ant-form-item-control-input-content
      @include flex

    .ant-btn
      min-width: 56px
      height: 20px

      span
        font-size: 11px !important

    &:nth-child(2)
      margin-left: 6px

  .bets-control-edit-form-edit-btn
    color: #5a6882

    &:hover
      color: #006EA0
      cursor: pointer

    &:active
      color: #42B2E6

    .anticon-edit
      font-size: 16px
      margin-right: 6px

.bets-user-tooltip
  min-height: 234px
  min-width: 170px
  max-width: 300px
  display: flex
  align-items: center
  justify-content: center
  color: black

.bets-table-bonus-tag
  line-height: 10px
  margin: 0
  position: absolute
  right: 0
  top: 0
  border-radius: 0 0 0 2px
  padding: 0 2px
  font-size: 9px
  border: 0
  text-transform: uppercase
